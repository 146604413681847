html {
    position: relative;
    min-height: 100%;
}

body {
    background: $backgroundColor;
    color: $bodyColor;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: $footerHeight;

    a,
    a:link,
    a:visited,
    a:hover,
    a:active {
        color: $link;
        text-decoration: none;
    }
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $footerHeight;
    font-size: 12px;
    color: $link;
}

.input-block-level {
    min-height: 100px;
}

#icons {
    margin-left: 10px;
}

#icons ul li a {
    border: 1px solid $backgroundColor;
    padding: 5px;
    text-decoration: none;
    font-size: 35px;
    margin-top: 14px;
    display: inline-block;
    zoom: 1;
    width: 40px;
    text-align: center;
}

#icons ul li {
    list-style-type: none;
    padding-left: 0;
    padding-right: 0;
}

.box {
    width: 230px;
    height: 230px;
    display: block;
    background: $backgroundColor;
    margin: 0 auto;
    border-radius: 100%;
    border: 3px solid $boxBorder;
    box-shadow: 0 2px 30px $backgroundColor;
    animation: shadow 10s infinite ease;
}

.box img {
    display: block;
    width: 220px;
    height: 220px;
    margin: 2px auto;
    border-radius: 100%;
    border-style: none;
}

@include keyframes(shadow) {
    10% {
        box-shadow: 0 2px 30px $black;
    }

    20% {
        box-shadow: 0 2px 30px $black;
    }

    30% {
        box-shadow: 0 2px 30px $black;
    }

    40% {
        box-shadow: 0 2px 30px $black;
    }

    90% {
        box-shadow: 0 2px 30px $white;
    }
}

.maintext {
    width: 50%;
    overflow: auto;
    margin: 5% auto 0;
}

.maintext p {
    display: block;
    width: 100%;
    margin: 0.2em 0;
    clear: both;
    font-family: Courier, "Lucida Console", monospace;
    font-size: 18px;
}

.maintext p span.content {
    float: left;
}

#insert-point {
    position: relative;
    display: none;
    height: 18px;
    width: 2px;
    background: $white;
    animation: fader 1s linear infinite;
    float: left;
}
