.vhp {
    div#bar,
    div#footer,
    span#title {
        position: relative;
    }

    div#bar,
    div#footer,
    div#video-container {
        float: left;
        width: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 1em;
    }

    a,
    a:active,
    a:link,
    a:visited {
        color: $vhpLink;
    }

    a:hover {
        color: $vhpLinkHover;
    }

    a.view-collection {
        font-weight: 700;
        font-size: 1.2em;
    }

    img {
        border: 0;
    }

    ul {
        list-style: none;
    }

    div#footer {
        z-index: 0;
        clear: both;
        padding: 105px 0 50px;
        background: url("../images/vhp/footer_top.png") top repeat-x;
        line-height: 1.4em;
        text-shadow: 1px 1px 1px $white;
    }

    span#title {
        top: 0.05em;
    }

    span#title-text {
        font-weight: 700;
        font-size: 1.4em;
        color: $odGreen;
    }

    .header {
        padding: 1.5em;
    }

    .header h2 {
        margin-bottom: 10px;
    }

    .header h4 {
        margin-bottom: 20px;
        padding: 0.7em 0;
        border-top: 4px double $odGreen;
        border-bottom: 4px double $odGreen;
        font-size: 0.9em;
        font-weight: 400;
        color: $black;
    }

    div#bar {
        z-index: 1;
        height: 25px;
        background: url("../images/vhp/bar_gradient.png") top repeat-x $odGreen;
        border-top: 1px solid $vhpBodyColor;
        border-bottom: 1px solid $vhpBodyColor;
    }

    div#video-container {
        clear: both;
        padding: 50px 0;
        background-color: $white;
    }
}
