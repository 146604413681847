@import "../variables";

:root {

    /** ==================================================================== **/

    /** Define skin named variables here                                    **/

    /** ==================================================================== **/
    --skin-vhp-background-color: #{$vhpBackgroundColor};
}
