$backgroundColor: #011627;
$bodyColor: #ecf0f1;
$blue: #37709c;
$blueIconColor: #ecf0f1;
$blueIconHover: #2c3e50;
$blackIconHover: #555;
$blackIconBorder: #f36f29;
$white: #fff;
$black: #000;
$link: #464646;
$odGreen: #4b5320;
$gray: gray;
$boxBorder: #8ceffb;
$desktopWidth: 1025px;
$tabletWidth: 768px;
$mobileWidth: 320px;
$footerHeight: 10px;

$vhpBackgroundColor: #dfdac7;
$vhpBodyColor: #333;
$vhpLink: #83831f;
$vhpLinkHover: #7a4012;

$errorPageColor1: #243442;
$errorPageColor2: #666;
$errorPageColor3: #ffc300;
